<template>

<div class="row">

      <ca-popup
        :header="open.header"
        :use-close-action="false"
        :toggle="open.popup"
        @close="clear()"
        v-show="open.data != null"
        class="investor__popup"
      >
        <template v-slot:content>
          <div class="po__container">
          <div class="row" v-for="(criterial, index) in open.data" :key="index">
            <div class="check-items">
              <input type="checkbox" name="" id="" @change="add(index)" :checked="investors[open.criteria].includes(index)? true: false"> 
              <span>
               {{criterial}}
              </span>
            </div>
          </div>
          </div>
        </template>

      </ca-popup>

    <form class="form col-xs-12">
      <div class="form__group">
        <label for="company" class="form__label">Company Name</label>
        <input
          type="text"
          class="form__input"
          placeholder="e.g Caena"
          id="company"
          v-model="investors.company"
          name="company"
        />
      </div>

      <div class="form__group">
        <label for="lead_deals" class="form__label">lead Investor</label>
        <input
          type="text"
          class="form__input"
          placeholder="e.g Johnathan"
          id="lead_deals"
          v-model="investors.lead_deals"
          name="lead_deals"
      />
      </div>

      <div class="form__group">
        <label for="hq_location" class="form__label">Headquarter Location</label>
        <input
          type="text"
          class="form__input"
          placeholder="e.g London"
          id="hq_location"
          v-model="investors.hq_location"
          name="hq_location"
      />
      </div>
      <div class="form__group">
        <label for="firstName" class="form__label">Contact Name</label>
        <input
          type="text"
          class="form__input"
          placeholder="e.g Johnathan"
          id="firstName"
          v-model="investors.contact_name"
          name="first_name"
        />
      </div>

      <div class="form__group">
        <label for="firstName" class="form__label">Contact Number</label>
        <input
          type="text"
          class="form__input"
          placeholder="e.g Johnathan"
          id="firstName"
          v-model="investors.contact_number"
          name="first_name"
        />
      </div>

      <div class="form__group">
        <label for="website" class="form__label">Website</label>
        <input
          type="text"
          class="form__input"
          placeholder="e.g https://caena.io"
          id="website"
          v-model="investors.website"
          name="website"
        />
      </div>
      <div class="form__group">
        <label for="firstName" class="form__label">Note</label>
        <input
          type="text"
          class="form__input"
          placeholder="e.g Johnathan"
          id="firstName"
          v-model="investors.Notes"
          name="first_name"
        />
      </div>
            <div class="investment__group">
        <div class="form__header">
        Investment Stage
        </div>
        <div class="items">
          <span class="selected" v-for="(stage, index) in investors.investment_stage" :key="index">
          {{list.investment_stage[stage]}}
            
            <span class="cancel" @click="remove(index, 'investment_stage')"> 
              x
            </span>
          </span>
        </div>

        <div class="form__group" >
          <ca-button class="ghost" :type="'ghost'" :size="'small'" :icon="'plus'" @click.native="openPopup(list.investment_stage,'Investment Stage', 'investment_stage'  )">
            Add
          </ca-button>
         </div>
      </div>
      
      <div class="investment__group">
        <div class="form__header">
        Startup Stage
        </div>
        <div class="items">
        <span class="selected" v-for="(stage, index) in investors.startup_stage" :key="index">
          {{list.startup_stage[stage]}}
            
            <span class="cancel"  @click="remove(index, 'startup_stage')"> 
              x
            </span>
          </span>
        </div>
      
        <div class="form__group">
          <ca-button class="ghost" :type="'ghost'" :size="'small'" :icon="'plus'" @click.native="openPopup(list.startup_stage,'Startup Stage', 'startup_stage'  )">
            Add
          </ca-button>
        </div>
      </div>
      <div class="investment__group">
        <div class="form__header">
              Industries
        </div>
        <div class="items">
          <span class="selected" v-for="(stage, index) in investors.sector" :key="index">
          {{list.industries[stage]}}
            <span class="cancel"  @click="remove(index, 'sector')"> 
              x
            </span>
          </span>
        </div>

        <div class="form__group">
          <ca-button class="ghost" :type="'ghost'" :size="'small'" :icon="'plus'"  @click.native="openPopup(list.industries,'Industries', 'sector'  )">
            Add
          </ca-button>
        </div>
        
       
      </div>
<!-- 
            <div class="investment__group">
        <div class="form__header">
              Countries of Investment
        </div>
        <div class="items">
          <span class="selected" v-for="(stage, index) in investors.countries" :key="index" >
            {{list.countries.name[stage]}}
            <span class="cancel"> 
              x
            </span>
          </span>
        </div>

        <div class="form__group">
          <ca-button class="ghost" :type="'ghost'" :size="'small'" :icon="'plus'" @click.native="openPopup(list.countries,'Countries of investment', 'countries'  )">
            Add
          </ca-button>
        </div>
        
       
      </div> -->

      <div class="investment__group">
        <div class="form__header">
        Investor Type
        </div>
        <div class="items">
          <span class="selected" v-for="(stage, index) in investors.investor_type" :key="index">
          {{list.investor_type[stage]}}
            <span class="cancel" @click="remove(index, 'investor_type')"> 

              x
            </span>
          </span>
        </div>

        <div class="form__group">
          <ca-button class="ghost" :type="'ghost'" :size="'small'" :icon="'plus'" @click.native="openPopup(list.investor_type,'Investor type', 'investor_type'  )">>
            Add
          </ca-button>
        </div>
        
       
      </div>

      <div class="investment__group">
        <div class="form__header">
       Investment Type
        </div>
        <div class="items">
          <span class="selected" v-for="(stage, index) in investors.investment_type" :key="index">
            {{list.investment_type[stage]}}
            <span class="cancel"  @click="remove(index, 'investment_type')"> 
              x
            </span>
          </span>
        </div>

        <div class="form__group">
          <ca-button class="ghost" :type="'ghost'" :size="'small'" :icon="'plus'" @click.native="openPopup(list.investment_type,'Investment type', 'investment_type'  )">
            Add
          </ca-button>
        </div>
        
       
      </div>


      <div class="form__group">
        <ca-button @click.native="editInvestor" :disabled="loading">
          <span>Save Changes</span>
          <span v-if="loading"><i class="fa fa-spinner fa-spin"></i></span>
        </ca-button>
      </div>
    </form>
  </div>
</template>

<script>
import CaButton from "../../../components/buttons/CaButton";
import axios from "@/axios";
import CaPopup from "../../../components/popup/CaPopup";


export default {
  name: "EditUser",
  components: { CaButton, CaPopup },
  data() {
    return {
      loading: false,
     investors: {
        company:'',
        lead_deals: "",
        hq_location: "",
        investment_stage:[],
        startup_stage: [],
        // Sector:[],
        // geography:[],
        investor_type:[],
        investment_type:[],
        contact_name: "",
        contact_number: "",
        website: "",
        Notes: "",
        
      },
      investmentStage:false,
      open:{
        popup:false,
        data:null,
        header:'',
        criteria:''
      },
      list:
      {
        startup_stage          : this.$_LIST_STARTUP_STAGE          ,
        distribution           : this.$_LIST_SALES_AND_DISTRIBUTION ,
        investment_stage       : this.$_LIST_INVESTMEMT_STAGE       ,
        investor_type          : this.$_LIST_INVESTOR_TYPE          ,
        investment_type        : this.$_LIST_INVESTMENT_TYPE        ,
        shareholders           : this.$_LIST_SHAREHOLDERS           ,
        industries             : this.parseArray(this.$_LIST_COMPANY_TYPE,'value','text'),
        countries              : this.parseArray(this.$_LIST_COUNTRIES,   'code' ,'name'),
      },
      roles: [],
    };
  },
  methods: {
    validation(){
      if (this.investors.investment_stage.length > 1) {
        this.$toast.error("Please select only one investment stage");
        return false;
      }

      if (this.investors.startup_stage.length > 2) {
        this.$toast.error("Please select only one startup stage");
        return false;
      }

      return true;
        
      
    },
    getUser(){
      const loading = this.$vs.loading();
      axios
        .get(
          `${this.$store.state.server.requestUri}/auth/investor/${this.$route.params.id}`
        )
        .then((res) => {
          const data = res.data.data;
          console.log(data)
          this.investors.company = data.company;
          this.investors.lead_deals = data.lead_deals;
          this.investors.hq_location = data.hq_location;
          this.investors.contact_name = data.contact_name;
          this.investors.contact_number = data.contact_number;
          this.investors.website = data.website;
          this.investors.Notes = data.Notes;
          this.investors.investor_type = (data.investor_type == ""||null)?[]:data.investor_type.split(',');
          this.investors.investment_type = (data.investment_type == ''||null)?[]:data.investment_type.split(',');
          this.investors.investment_stage = (data.investment_stage ==''||null)?[]:data.investment_stage.split(",");
          this.investors.startup_stage = (data.startup_stage == '')?[]:data.startup_stage.split(',');
          // this.investors.sector =(data.sector == ''||null)?[]: data.sector.split(',');
          // this.investors.geography = data.geography.split(',');
          console.log(this.investors)
          loading.close();
        })
        .catch((err) => {
          console.log(err);
          loading.close();
        });
    },

    editInvestor() {
      this.loading = true;
      // remove empty values from object
      const investors = this.investors;
      const data = {};
      for (const key in investors) {
        if (investors[key] !== "") {
          data[key] = investors[key];
        }
      }
      if(this.validation()){
      // console.log(this.investors)
      axios
        .patch(
          `${this.$store.state.server.requestUri}/admin/investor/update-account/${this.$route.params.id}`,
          this.investors
        )
        .then((res) => {
          this.$toast("User updated successfully", {
            position: "top-center",
            type: "success",
          });
          this.loading = false;
          const data = res.data.data;

          this.setUserInfo(data);
          console.log(data);
        })
        .catch((err) => {
          this.$toast("Error updating Investor", {
            position: "top-center",
            type: "error",
          });
          this.loading = false;
          console.log(err);
        });
      }
    },
    add(index){
      if (this.investors[this.open.criteria].includes(index)) {
        const position = this.investors[this.open.criteria].indexOf(index)
        this.investors[this.open.criteria].splice(position, 1);
      }else{
         if (this.open.criteria == 'investment_stage' && this.investors.investment_stage.length == 1) {
          this.$toast.error("Please select only one investment stage");
          return false;
        }
          
        if (this.open.criteria == 'startup_stage' && this.investors.startup_stage.length == 2) {
          this.$toast.error("Please select only two startup stage");
          return false;
        }
        this.investors[this.open.criteria].push(index)
      }
      

    },

    remove(index, critaria){
      const position = this.investors[critaria].indexOf(index)
      this.investors[critaria].splice(position, 1);
      console.log(this.investors[critaria])
      // this.investors[critaria] = data;
    },

    clear(){
      this.open.popup = false;
      this.open.data = null;
      this.open.header = '';
      this.open.criteria = '';
    },

    parseArray  ( a, b, c, d = {} )                       /*  */
    {
      for ( var e in a )
      {
        d[ a[e][b] ] = a[e][c]
      }
      return d
    },

    openPopup(data,header,criteria){
      this.open.popup = true;
      this.open.data = data;
      this.open.header = header;
      this.open.criteria = criteria;
      console.log(this.open)
    },
  },
  created() {
    this.getUser();
  },
};
</script>

<style scoped>
.selected{
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  /* margin-bottom: 20px; */
  margin:30px 10px;

}
/* .selected span{
  font-weight: bold;
} */
.selected .cancel{
  cursor: pointer;
  /* color: #ff0000; */
}

.po__container{
  height: 400px;
  margin: 0 auto;
  overflow: auto;
}
.investor__popup{
  display: block;
  width: 100%;

}
.investor__popup .form__group{
  /* margin-bottom: 10px; */
}
.form__header{
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  width: 100%;
}
.investment__group{
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 10px; */
  flex-wrap: wrap;
  border-bottom:1px solid #ccc;
}
.investment__group{
  width: 100%;
  margin-bottom: 10px;
}

.items{
  width: 100%;
  min-height: 50px;
  display: flex;
  /* justify-content: space-between; */
  /* margin-bottom: 10px; */
  flex-wrap: wrap;
}
</style>
